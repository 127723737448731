#btcChart {
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    font-family: "Lato";
}

.btc-chart-container {
    height: 70%;
    max-height: 70%;
    max-width: 100%;
}

.chart-h {
    height: 28rem;
}

@media screen and (min-width: 768px) {
    .chart-h {
        height: 30rem;
    }

    .chart-h.with-notification {
        height: 40.25rem;
    }
}