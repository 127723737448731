body {
  margin: 0;
  font-family: 'Lato';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
}

h1, h2, h3, h4, h5 {
  font-family: "Lato", serif !important;
}

.font-serif, h6, p {
  font-family: "Lato", serif !important;
}

.font-sans {
  font-family: "Lato", serif !important;
}

input, textarea {
  color: white !important;
}

/* Tailwind Overrides */

/* THEME COLORS */

/* gray */

.bg-gray {
  background-color: #454c52 !important;
}

.bg-gray-500 {
  background-color: #454c52 !important;
}

.bg-gray-200 {
  background-color: #454c5250 !important;
}

.text-gray {
  background-color: #454c52 !important;
}

.from-gray-500 {
  --tw-gradient-from: #454c52 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0));
}

.to-gray-700 {
  --tw-gradient-to: #454c52 !important;
}

.border-gray-500 {
  border-color: #454c52 !important;
}

.hover\:bg-gray-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(69, 76, 82, var(--tw-bg-opacity)) !important;
}

.hover\:bg-gray:hover {
  background-color: rgba(69, 76, 82, var(--tw-bg-opacity)) !important;
}

/* green */

.bg-green {
  background-color: #ffc930 !important;
}

.bg-green-500 {
  background-color: #ffc930 !important;
}

button.text-green-500 {
  --tw-text-opacity: 1;
  color: rgba(250, 178, 61, var(--tw-text-opacity)) !important;
}

.border-green-500 {
  border-color: rgba(250, 178, 61, var(--tw-border-opacity)) !important;
}

.from-green-500 {
  --tw-gradient-from: #FAB23D !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 152, 0, 0));
}

.to-green-700 {
  --tw-gradient-to: #FAB23D !important;
}

.mt-input-green-500:focus + label:after{
  border-color:rgba(250, 178, 61, var(--tw-border-opacity)) !important;
}

.shadow-lg-green {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12), 0 10px 10px -5px rgba(0, 0, 0, 0.04), 0 13px 24px -11px rgba(250, 178, 61, 0.04) !important;
}

.shadow-md-green {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1), 0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(250, 178, 61, 0.2) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

/* beige */

.bg-beige {
  background-color: #CCCBBB;
}

.text-beige {
  color: #CCCBBB;
}

/* misc */

.hover\:text-white:hover {
  color: #ffffff !important;
}

/* color defs */

.text-success {
  color: rgb(76,175,80) !important;
}

.bg-success {
  background: rgba(76,175,80, 0.8) !important;
}

.border-success {
  background: rgb(76,175,80, 0.5) !important;
}

/* 3rd party styling */

.DayPicker-Day--today {
  color: #FAB23D;
}

.invisible {
  visibility: hidden !important;
}

.arrow-light .tippy-svg-arrow {
  fill: black !important;
}

