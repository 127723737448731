/* body {
    margin: 0;
    font-family: 'Raleway';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #000000;
}

.font-serif {
font-family: "Raleway", serif !important;
}

p, a, h1, h2, h3, h4, h5, h6 {
color: #f9fcfe !important;
} */

.letters-no-spacing{
  letter-spacing: normal !important;
}

.bg-primary {
    background-color: #caccba !important;
}

.bg-image {
    background-image: url("../img/black-bg-image.png"); /* The image used */
    background-color: #cccccc; /* Used if the image is unavailable */
    height: 100vh; /* You must set a specified height */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
}

.bg-secondary {
    background-color: #ffc930 !important;
}

.video-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 50vh;
    width: 50vw;
}

.video-wrapper {
    max-width: 1300px;
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
    border-radius: 10px;
  }

.break-all-child > * {
  word-break: break-all !important;
}

/* Custom Modal */

.modal-window {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all 0.3s;
  z-index: 999999999999999;
}

.modal-window:target {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}
.modal-window > div {
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2em;
  background: black;
}

.modal-window header {
  font-weight: bold;
}
.modal-window h1 {
  font-size: 150%;
  margin: 0 0 15px;
  color: white;
}

.modal-close {
  color: #aaa;
  line-height: 50px;
  font-size: 80%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 70px;
  text-decoration: none;
  cursor: pointer;
}
.modal-close:hover {
  color: white;
}

.moda-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.modal-window > div {
  border-radius: 1rem;
}

.modal-window div:not(:last-of-type) {
  margin-bottom: 15px;
}

.small {
  color: lightgray;
}

.modal-btn {
  background-color: white;
  padding: 1em 1.5em;
  border-radius: 1rem;
  text-decoration: none;
}
.modal-btn i {
  padding-right: 0.3em;
}
